type Config = {
  API_URL: string;
  AUTH_DISABLE_PKCE: boolean;
  AUTH_IDENTITY_PROVIDER: string;
  AUTH_PATIENT_CLIENT_ID: string;
  AUTH_SCOPE: string;
  AUTH_TOKEN_URL: string;
  AUTH_URL: string;
  ELASTIC_APM_AGENT_ACTIVE: boolean;
  ELASTIC_APM_SERVER_URL: string | undefined;
  ENVIRONMENT: Environment;
  GEOGRAPHY: string;
  WEBSITE_URL: string;
  ALLOW_GUARDIANSHIP: boolean;
};

type Environment = (typeof ENVIRONMENTS)[number];

type Geography = (typeof GEOGRAPHIES)[number];

const AUTH_URL_UK = "https://auth.sandpit.signin.nhs.uk";
const ENVIRONMENTS = ["development", "production", "staging"] as const;
const GEOGRAPHIES = ["SE", "UK"] as const;

function keys<T extends Record<PropertyKey, unknown>>(
  value: Record<keyof T, unknown>,
) {
  return Object.keys(value) as (keyof T)[];
}

const CONFIG_KEYS = keys<Config>({
  API_URL: true,
  AUTH_DISABLE_PKCE: true,
  AUTH_IDENTITY_PROVIDER: true,
  AUTH_PATIENT_CLIENT_ID: true,
  AUTH_SCOPE: true,
  AUTH_TOKEN_URL: true,
  AUTH_URL: true,
  ELASTIC_APM_AGENT_ACTIVE: true,
  ELASTIC_APM_SERVER_URL: true,
  ENVIRONMENT: true,
  GEOGRAPHY: true,
  WEBSITE_URL: true,
  ALLOW_GUARDIANSHIP: true,
});

const baseConfig = {
  ELASTIC_APM_AGENT_ACTIVE: false,
  ELASTIC_APM_SERVER_URL: undefined,
};

const seBaseConfig = {
  ...baseConfig,
  AUTH_DISABLE_PKCE: false,
  AUTH_IDENTITY_PROVIDER: "signicat",
  AUTH_SCOPE: "openid",
  GEOGRAPHY: "SE",
  ALLOW_GUARDIANSHIP: true,
} satisfies Partial<Config>;

const ukBaseConfig = {
  ...baseConfig,
  AUTH_DISABLE_PKCE: true,
  AUTH_IDENTITY_PROVIDER: "nhs",
  AUTH_PATIENT_CLIENT_ID: "zymego-patient-portal-nhs-login-development",
  AUTH_SCOPE: "openid profile email phone gp_integration_credentials",
  AUTH_URL: AUTH_URL_UK,
  GEOGRAPHY: "UK",
  ALLOW_GUARDIANSHIP: false,
} satisfies Partial<Config>;

const configs = {
  developmentSE: (hostname: string): Config => {
    const authUrl = "https://auth.local.zymego.app/auth/open";
    return {
      ...seBaseConfig,
      API_URL: "https://localhost:3010/patient/graphql",
      AUTH_PATIENT_CLIENT_ID: "sandbox-gentle-cheese-220",
      AUTH_TOKEN_URL: `${authUrl}/connect/token`,
      AUTH_URL: "https://auth.local.zymego.app/auth/open",
      ENVIRONMENT: "development",
      WEBSITE_URL: `https://${hostname}:3000`,
    };
  },
  stagingSE: (hostname: string): Config => {
    const apiUrl = `https://api.${hostname}`;
    const authUrl = `https://auth.${hostname}/auth/open`;
    return {
      ...seBaseConfig,
      API_URL: `${apiUrl}/patient/graphql`,
      AUTH_IDENTITY_PROVIDER: "signicat",
      AUTH_PATIENT_CLIENT_ID: "sandbox-melancholy-eagle-485",
      AUTH_TOKEN_URL: `${authUrl}/connect/token`, // https://auth.staging.zymego.app/auth/open/.well-known/openid-configuration
      AUTH_URL: authUrl,
      ELASTIC_APM_SERVER_URL: `${apiUrl}/apm`,
      ENVIRONMENT: "staging",
      WEBSITE_URL: "https://www.zymego.com",
    };
  },
  productionSE: (hostname: string): Config => {
    const apiUrl = `https://api.${hostname}`;
    const authUrl = `https://auth.${hostname}/auth/open`;
    return {
      ...seBaseConfig,
      API_URL: `${apiUrl}/patient/graphql`,
      AUTH_IDENTITY_PROVIDER: "signicat",
      AUTH_PATIENT_CLIENT_ID: "prod-petty-goat-588",
      AUTH_TOKEN_URL: `${authUrl}/connect/token`, // https://auth.zymego.app/auth/open/.well-known/openid-configuration
      AUTH_URL: authUrl,
      ELASTIC_APM_SERVER_URL: `${apiUrl}/apm`,
      ENVIRONMENT: "production",
      WEBSITE_URL: "https://www.zymego.com",
    };
  },
  developmentUK: (hostname: string): Config => {
    const authLocalUrl = "https://localhost:3010/auth";
    return {
      ...ukBaseConfig,
      API_URL: "https://localhost:3010/patient/graphql",
      AUTH_TOKEN_URL: `${authLocalUrl}/token`,
      ENVIRONMENT: "development",
      WEBSITE_URL: `https://${hostname}:3000`,
    };
  },
  stagingUK: (): Config => {
    // TODO: Switch to the URL below when DNS issue has been resolved.
    // const apiBaseUrl = `https://api.${hostname}`;
    const apiBaseUrl = "https://zymego-uk-staging-mxrtkbortq-nw.a.run.app";
    const authLocalUrl = `${apiBaseUrl}/auth`;
    return {
      ...ukBaseConfig,
      API_URL: `${apiBaseUrl}/patient/graphql`,
      AUTH_TOKEN_URL: `${authLocalUrl}/token`,
      ELASTIC_APM_SERVER_URL: `${apiBaseUrl}/apm`,
      ENVIRONMENT: "staging",
      WEBSITE_URL: "https://www.zymego.com",
    };
  },
  productionUK: (hostname: string): Config => {
    const apiBaseUrl = `https://api.${hostname}`;
    const authLocalUrl = `${apiBaseUrl}/auth`;
    return {
      ...ukBaseConfig,
      API_URL: `${apiBaseUrl}/patient/graphql`,
      AUTH_TOKEN_URL: `${authLocalUrl}/token`,
      ELASTIC_APM_SERVER_URL: `${apiBaseUrl}/apm`,
      ENVIRONMENT: "production",
      WEBSITE_URL: "https://www.zymego.com",
    };
  },
};

function getConfig({
  environment = import.meta.env,
  hostname = globalThis.location?.hostname ?? "localhost",
}: {
  environment?: Record<string, string | undefined>;
  hostname?: string;
} = {}): Config {
  const parsed = parseHostname({
    geography: environment.VITE_GEOGRAPHY,
    hostname,
  });

  const inferredVariables =
    configs[`${parsed.environment}${parsed.geography}`](hostname);

  const environmentVariables = Object.fromEntries(
    Object.entries({
      ELASTIC_APM_AGENT_ACTIVE:
        environment.VITE_ELASTIC_APM_AGENT_ACTIVE === "true",
      ELASTIC_APM_SERVER_URL: environment.VITE_ELASTIC_APM_SERVER_URL,
      GEOGRAPHY: environment.VITE_GEOGRAPHY,
    }).filter(([, value]) => value !== undefined),
  );

  return validateConfig({ ...inferredVariables, ...environmentVariables });
}

function isDevelopmentHostname(hostname: string): boolean {
  const parts = hostname.split(".");
  const zymegoIndex = parts.indexOf("zymego");
  return zymegoIndex === -1 || parts[zymegoIndex - 1] === "local";
}

function parseHostname({
  geography,
  hostname,
}: {
  geography?: string;
  hostname: string;
}): { environment: Environment; geography: Geography } {
  const parts = hostname.split(".");

  const environment = isDevelopmentHostname(hostname)
    ? "development"
    : parts
        .map((part) => part.toLowerCase())
        .find((part) => ENVIRONMENTS.includes(part as Environment));

  if (!GEOGRAPHIES.includes(geography as Geography)) {
    geography = parts
      .map((part) => part.toUpperCase())
      .find((part) => GEOGRAPHIES.includes(part as Geography));
  }

  return {
    environment: (environment as Environment) || "production",
    geography: (geography as Geography) || "SE",
  };
}

function validateConfig(config: Config) {
  for (const key of CONFIG_KEYS) {
    if (!(key in config)) {
      throw new ReferenceError(`Missing config variable: ${key}`);
    }
  }

  return config as Config;
}

export { getConfig, isDevelopmentHostname, parseHostname };
export type { Config };
