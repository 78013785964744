import {
  InMemoryWebStorage,
  UserManager,
  WebStorageStateStore,
} from "oidc-client-ts";

import { getConfig } from "./runtime-config";

const {
  AUTH_DISABLE_PKCE,
  AUTH_PATIENT_CLIENT_ID,
  AUTH_SCOPE,
  AUTH_TOKEN_URL,
  AUTH_URL,
} = getConfig();

const { origin } = globalThis.location;

const redirectUri = `${origin}/login`;

const userManager = new UserManager({
  authority: AUTH_URL,
  client_id: AUTH_PATIENT_CLIENT_ID,
  disablePKCE: Boolean(AUTH_DISABLE_PKCE),
  redirect_uri: redirectUri,
  response_type: "code",
  scope: AUTH_SCOPE,
  accessTokenExpiringNotificationTimeInSeconds: 3,
  silent_redirect_uri: `${origin}/login/silent`,
  userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
  post_logout_redirect_uri: `${origin}/logout/callback`,
  redirectMethod: "replace",
  metadataSeed: {
    token_endpoint: `${AUTH_TOKEN_URL}?redirect_uri=${redirectUri}`,
  },
});

// TODO: Listen for "userUnloaded" or other relevant event and redirect to login page directy instead of logout to landing page.
// This has to be coordinated with the retry and/or logoutLink in apollo client setup that do the logout if 401 is received.

export { userManager };
